.no-underline {
  text-decoration: none;
}
.logo {
  width: 10px;
  height: 10px;
  margin-left: 10%;
  padding-top: 10%;
  border-radius: 50%;
}

.white {
  color: #d9d9d9;
}
.blue {
  color: #0f2862;
}
.pink {
  color: #ff00f0;
}
#red {
  color: #ff3a22;
}
.Gerua {
  color: #fc4a1a;
}
.Options {
  color: #1561ad;
}
.space {
  padding-right: 10px;
}
.column {
  display: flex;
  flex-direction: column;
}
.center {
  align-items: center;
}
#soLink {
  color: #ff00f0;
}
#menu {
  background: #0f2862;
  color: #fffff0;
}
#circlelogo {
  width: 5%;
}
#brlogo {
  width: 30%;
}
#error {
  color: #ff00f0;
  text-decoration: none;
}

.page-format {
  background-color: #beef00;
  height: 200vh;
}

.powered {
  text-decoration: none;
  color: #0f2862;
}
#Horz {
  text-decoration: none;
  color: #0f2862;
}
#brandlogo {
  text-align: center;
  padding-left: 10vw;
}
.HorzMenu {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background: #fffff0;
}
.HorzMenu h3 {
  color: #0f2862;
}
.HorzMenu h2 {
  color: #0f2862;
}

@media screen and (width <= 410px) {
  .HorzMenu {
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: black;
  }
  .HorzMenu h3 {
    color: aliceblue;
  }
  .HorzMenu h2 {
    color: aliceblue;
  }
}
.title-style {
  background-color: #0f2862;
  display: flex;
  justify-content: space-between;
  color: #d9d9d9;
}
@media screen and (width <= 410px) {
  .title-style {
    display: flex;
    flex-direction: column;
  }
}
.header-item {
  display: flex;
  justify-content: space-between;
  color: #1561ad;
}
@media screen and (width <= 410px) {
  .header-item {
    display: flex;
    flex-direction: column;
  }
}
