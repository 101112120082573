#error {
  color: #ff00f0;
  text-decoration: none;
}
#red {
  color: #ff3a22;
}
.Options {
  color: #1561ad;
}
.Gerua {
  color: #fc4a1a;
}

.no-underline {
  text-decoration: none;
}
.title-style {
  background-color: #0f2862;
  display: flex;
  justify-content: space-between;
  color: #d9d9d9;
}
.page-format {
  background-color: #beef00;
  height: 200vh;
}
.header-item {
  display: flex;
  justify-content: space-between;
  color: #1561ad;
}

.menu {
  display: flex;
  justify-content: space-around;
  background: #fffff0;
}

.Raw {
  display: flex;
  justify-content: space-around;
}
.Right {
  float: right;
}
.Left {
  text-align: left;
}
.App {
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
