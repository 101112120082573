.link-preview {
  border: 1px solid #fffff0;
  display: flex;
  margin-top: 20px;
  max-width: 500px;
  background-color: #004385;
}

.preview-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.preview-content {
  padding: 10px;
}

.preview-title {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: #beef00;
}

.preview-description {
  font-size: 14px;
  color: #d9d9d9;
}
